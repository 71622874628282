import React, { useEffect, useRef } from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper';
import { Link } from 'gatsby';
import clsx from 'clsx';
import SplitPane from './split-pane';
import FancyTitle from './fancy-title';
import { useWindowSize } from './layout';
import { elementIsVisibleInViewport } from '../lib/utils';

// eslint-disable-next-line import/no-unresolved
import 'swiper/css';
// eslint-disable-next-line import/no-unresolved
import 'swiper/css/virtual';
// eslint-disable-next-line import/no-unresolved
import 'swiper/css/pagination';

function ScrollSlider({
  items, background, itemHeadline = false, subheadlineMobile = false,
}) {
  const ref = useRef();

  const windowSize = useWindowSize();

  const getHeight = (element) => {
    const subtitleMobileHeight = document.querySelectorAll(element);
    let maxHeight = 0;

    for (let i = 0; i < subtitleMobileHeight.length; i++) {
      subtitleMobileHeight[i].style = 'height: auto';
    }

    for (let i = 0; i < subtitleMobileHeight.length; i++) {
      if (subtitleMobileHeight[i].offsetHeight > maxHeight) {
        maxHeight = subtitleMobileHeight[i].offsetHeight;
      }
    }

    for (let i = 0; i < subtitleMobileHeight.length; i++) {
      subtitleMobileHeight[i].style = `height: ${maxHeight}px`;
    }
  };

  useEffect(() => {
    window.lastScrollY = 0;

    const handleImageVisibility = () => {
      if (window.innerWidth >= 1024) {
        if (ref.current) {
          const allImages = [...ref.current.querySelectorAll('[data-image]')];
          const refY = ref.current.getBoundingClientRect().y;

          if (refY < 0) {
            ref.current.querySelectorAll('[data-text]').forEach((item, index) => {
              if (index >= allImages.length - 1) return;
              const image = ref.current.querySelector(`[data-image="${item.dataset.text}"]`);
              if (elementIsVisibleInViewport(item, true)) {
                image?.classList.add('opacity-100');
                image?.classList.remove('opacity-0');
              } else {
                image?.classList.add('opacity-0');
                image?.classList.remove('opacity-100');
              }
            });
          }
        }
      }
      window.lastScrollY = window.scrollY;
    };

    handleImageVisibility();
    window.addEventListener('scroll', handleImageVisibility);

    return () => {
      window.removeEventListener('scroll', handleImageVisibility);
    };
  }, []);

  return (
    <div className="flex flex-wrap lg_ref_page:flex-nowrap h-full top-0 items-start lg_ref_page:grid grid-cols-[1.5fr_1fr] auto-rows-fr lg:-ml-16 -ml-4 w-screen lg_ref_page:w-[calc(100%+4rem)]" ref={ref}>
      {windowSize.width < 1025 ? (
        <>
          {itemHeadline && items && items[0].title && (
            <FancyTitle
              className="px-4"
              text={items[0].title}
              marginBottom="mb-10"
              tag="h2"
            />
          )}
          <Swiper
            modules={[Pagination]}
            direction="horizontal"
            spaceBetween={0}
            slidesPerView={1}
            className="h-full w-screen"
            pagination={{ clickable: true }}
            onBeforeInit={() => getHeight('.subtitleMobile h3')}
            onResize={() => getHeight('.subtitleMobile h3')}
            autoHeight
          >
            {items && items.map((item) => (
              <SwiperSlide
                className={clsx(
                  items.length > 1 ? 'cursor-grab' : '',
                )}
                key={item.title}
                id={item.id ? item.id : null}
              >
                <SplitPane
                  className="h-full"
                  left={(
                    <div className="flex flex-col">
                      {(subheadlineMobile && !itemHeadline) && (
                      <div className="px-4 start subtitleMobile">
                        <FancyTitle
                          text={item.title || items[0].title}
                          subtitle={subheadlineMobile && item?.subtitle}
                          tag="h2"
                          marginBottom="mb-12 lg:mb-16 xl:mb-20"
                          textAlign="text-center lg:text-center"
                        />
                      </div>
                      )}

                      <GatsbyImage
                        data-swiper-parallax-y={400}
                        image={item.image.childImageSharp.gatsbyImageData}
                        alt={item.title}
                        className="w-full h-[50vh] lg:max-h-[50vh] xl:max-h-[60vh]"
                        backgroundColor={background || item.background_color || 'transparent'}
                      />
                    </div>
                  )}
                  right={(
                    <div className="p-4 xll:p-24 pb-8 xll:pb-24 h-full flex flex-col justify-end">
                      {/* {((itemHeadline && index > 0 && item.title) || !itemHeadline) && (
                      <div
                        data-swiper-parallax-opacity={0}
                        data-swiper-parallax={-250}
                        className="px-4 lg:px-24"
                        >
                        <FancyTitle
                          text={item.title}
                          subtitle={item?.subtitle}
                          tag="h2"
                          className="mb-8 lg:mb-12"
                        />
                      </div>
                    )} */}
                      <p
                        data-swiper-parallax-opacity={0}
                        data-swiper-parallax={-200}
                        dangerouslySetInnerHTML={{ __html: item.description }}
                        className={
                        clsx(
                          item?.link?.url ? '' : 'mb-8',
                        )
                      }
                      />
                      {item?.link?.url && (
                      <Link to={item.link.url} className="button-primary inline-block mt-4 mb-8 max-w-max mx-auto">{item.link.text}</Link>
                      )}
                    </div>
                )}
                  rightWidth="xll:w-2/5"
                  leftWidth="xll:w-3/5"
                />
              </SwiperSlide>
            ))}
          </Swiper>
        </>
      ) : (
        <>
          <div className="w-full grid place-content-center justify-left sticky top-0 h-screen col-start-1">
            {items && items.slice(0).reverse().map((item, index, array) => (
              // eslint-disable-next-line react/no-array-index-key
              <div key={`image-${index}`} className="transparent_background">
                {item.image && (
                <div
                  className={`${[array[index + 1]?.image?.name, array[index - 1]?.image?.name].includes(item.image.name) ? '' : 'transition-opacity duration-[300ms]'} absolute inset-0`}
                  data-image={array.length - index}
                  style={{ background: background || item.background_color || item.background || 'transparent' }}
                >
                  <GatsbyImage
                    image={item?.image?.childImageSharp?.gatsbyImageData}
                    alt={item.title}
                    className="w-full h-full"
                    backgroundColor={background || item.background_color || item.background || 'transparent'}
                  />
                </div>
                )}
              </div>
            ))}
          </div>

          {items && items.map((item, index) => (
            <div
              className="p-4 lg:p-8 xl:p-16 pb-8 lg:pb-24 h-full flex flex-col justify-center col-start-2 "
              key={item.id ? item.id : `${item.title}-${index}`}
              id={item.id ? item.id : null}
            >
              {(item.title || item.subtitle) ? (
                <div data-text={index + 1}>
                  <FancyTitle
                    text={item.title}
                    subtitle={item.subtitle}
                    tag="h2"
                  />
                </div>
              ) : (
                <div data-text={index + 1} />
              )}
              <p dangerouslySetInnerHTML={{ __html: item.description }} />
              {item?.link?.url && (
                <Link to={item.link.url} className="button-primary inline-block mt-4 max-w-max">{item.link.text}</Link>
              )}
            </div>
          ))}
        </>
      )}
    </div>
  );
}

export default ScrollSlider;
