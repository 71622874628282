import * as React from 'react';
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image';
import clsx from 'clsx';

function InfoBox({ data }) {
  return (
    <div className="relative lg:top-[43px]">
      {data.sua_winner && (
        <div className="absolute right-0 top-0 z-20">
          <StaticImage
            src="../assets/sua_winner_2023.png"
            className="w-36 md:w-48"
            alt="SUA Badge"
          />
        </div>
      )}
      <div className="absolute z-10 bottom-0 2xl:bottom-16 left-0 lg:left-1/2 lg:-translate-x-1/2 w-full 2xl:w-max lg:max-w-[100%">
        <div className="flex flex-wrap lg_ref_page:flex-nowrap flex-column lg_ref_page:flex-row items-center z-10 2xl:rounded-[4px] lg_ref_page:px-5 pt-6 pb-3 lg_ref_page:py-6  2xl:shadow-md bg-background-dark/50 dark:bg-background-dark/50 backdrop-blur 2xl:py-[50px]">
          <h1 className="text-center lg_ref_page:text-left mb-4 lg_ref_page:mb-0 fancy-title text-2xl lg_ref_page:text-4xl font-bold lg:mr-10 2xl:mr-24 basis-full lg_ref_page:basis-[unset] flex-wrap !flex-1 text-white">{data.title}</h1>
          <div
            className={
              clsx(
                data.tags && data.tags.length > 1 ? 'grid grid-cols-2' : 'flex justify-center',
                'lg_ref_page:!flex flex-row flex-wrap w-full lg_ref_page:w-[unset] gap-y-4',
              )
            }
          >
            {data.tags && data.tags.map((tag, index) => (
              <div key={tag} className="relative text-center">
                <span
                  className={
                    clsx(
                      'text-center lg_ref_page:text-left px-4 py-3 lg:border-l-2 border-latori-green-light text-md xl:text-xl basis-1/2 break-words lg_ref_page:basis-auto mb-0 lg:mt-0 text-white',
                      index === data.tags.length - 1 ? ' lg:pr-0 xl:mr-20' : '',
                      index % 2 ? 'border-l-2' : 'border-l-0',
                      'first-of-type:border-l-0',
                    )
                  }
                  key={tag}
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{ __html: tag }}
                />
                {index % 2 === 0 && index !== data.tags.length - 1 ? (
                  <span className="border-latori-green-light border-l-2 lg_ref_page:hidden absolute h-full right-0" />
                ) : ''}
              </div>
            ))}
          </div>
        </div>
      </div>
      <GatsbyImage
        image={data.image.childImageSharp.gatsbyImageData}
        alt={data.title}
        objectPosition={`${data.focal_point_x}% ${data.focal_point_y}%`}
        className="w-full lg:h-full h-[500px] object-fit"
      />
    </div>
  );
}

export default InfoBox;
