import * as React from 'react';

function SplitPane({
  left, right, flip, className, items = 'center', leftWidth = 'xl:w-1/3', rightWidth = 'xl:w-2/3',
}) {
  return (
    <div className={`flex flex-wrap xll:flex-nowrap ${className}`}>
      <div className={`w-full flex justify-left xl:justify-end items-${items}${leftWidth && ` ${leftWidth}`}`}>
        {left}
      </div>
      <div className={`w-full ${flip ? ' lg:-order-1' : ''}${rightWidth && ` ${rightWidth}`}`}>
        {right}
      </div>
    </div>
  );
}

export default SplitPane;
