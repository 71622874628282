import { graphql } from 'gatsby';
import * as React from 'react';
// import { Link } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { library, icon } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FancyTitle from '../components/fancy-title';
import LayoutConsumer from '../components/layout-context';
import Seo from '../components/seo';
import InfoBox from '../components/info-box';
import ScrollSlider from '../components/scroll-slider';

import * as icons from '../icons/icon-pack';
import ImagePane from '../components/image-pane';
import PipedriveForm from '../components/pipedrive-form';

// import ContactForm from '../components/contact-form'
// import References from '../components/references'

function UseCaseDetail({ data }) {
  library.add({ ...icons });

  const useCase = data.useCasesYaml;
  const flowImage = getImage(useCase.flow?.image);

  const samples = React.useMemo(() => {
    const { sample } = useCase;
    if (useCase.additional_samples && sample) {
      return [sample, ...useCase.additional_samples];
    } if (sample) {
      return [sample];
    }
    return [];
  }, [useCase]);

  return (
    <LayoutConsumer>
      {({ set }) => {
        set({ pageTitle: useCase.title, fullWidth: true });
        return (
          <>
            <Seo
              title={useCase.meta_title
                ? useCase.meta_title : useCase.title}
              description={useCase.meta_description
                ? useCase.meta_description : useCase.description}
            />
            <div className="use-case-info-box">
              <InfoBox data={useCase} />
            </div>
            <div className="px-4 lg:px-16 mt-[100px]">
              <FancyTitle text={useCase.subtitle} tag="h2" />
              <p className="leading-6" dangerouslySetInnerHTML={{ __html: useCase.description }} />
            </div>
            <div className="px-4 lg:px-16 pt-24">
              <FancyTitle
                text="Das sind die Vorteile"
                marginBottom="mb-[2em]"
                tag="h2"
              />
              <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-3 gap-6 ">
                {useCase.benefits && useCase.benefits.map((benefit) => (
                  <div key={`benefit-${benefit.title}`} className="flex flex-col justify-center items-center gap-6">
                    <div className="w-[60px] h-[60px] min-w-[60px] rounded-full bg-latori-green-dark flex justify-center items-center">
                      <FontAwesomeIcon
                        icon={icon(icons[benefit.icon])}
                        size="xl"
                        className="text-white"
                      />
                    </div>
                    <p className="m-0 text-center">{benefit.title}</p>
                  </div>
                ))}
              </div>
            </div>
            {(useCase.flow && useCase.flow.image) && (
              <div className={`px-4 lg:px-16 pt-24 ${!samples.length ? 'pb-24' : ''}`}>
                <FancyTitle
                  text={useCase.flow.title}
                  tag="h2"
                  className="hidden lg:block"
                />
                <ImagePane
                  image={(
                    <div>
                      {flowImage
                        && (
                        <GatsbyImage
                          image={flowImage}
                          alt={useCase.title}
                          className="w-full"
                        />
                        )}
                    </div>
                  )}
                  desktopImagePosition="right"
                  title={(
                    <FancyTitle
                      text={useCase.flow.title}
                      tag="h2"
                      className="mb-8 xl:mb-12 block lg:hidden"
                    />
                  )}
                  desktopImageWidth="lg:w-1/2"
                  desktopTitleWidth="lg:w-1/2"
                  desktopClassName="gap-0"
                  desktopTitleVerticalPosition="center"
                  content={(
                    <div className="p-3 w-full">
                      {useCase.flow?.items.map((flow, i) => (
                        <div className="flex py-4" key={`d-${flow.title}`}>
                          <div className="text-center pr-6">
                            <div className="rounded-full !w-[60px] !h-[60px] flex justify-center items-center bg-latori-green-dark">
                              <span className="text-white text-2xl">{String(i + 1).padStart(2, '0')}</span>
                            </div>
                            <div className="mt-6 mx-auto bg-latori-green w-[1px] h-[calc(100%-75px)]" />
                          </div>
                          <div className="pt-2 min-h-[300px]">
                            <FancyTitle
                              text={flow.title}
                              fontSize="text-lg"
                              marginBottom="0"
                              tag="h3"
                            />
                            <p className="pt-4 leading-6" dangerouslySetInnerHTML={{ __html: flow.description }} />
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                />
              </div>
            )}

            {samples.length > 0 && (
              <div className="transparent_background px-4 lg:px-16 py-24">
                <ScrollSlider items={samples} />
              </div>
            )}

            {useCase.video && (
              <div className="px-4 lg:px-16 pb-24">
                <div className="aspect-video">
                  <iframe
                    title={`${useCase.title} Video`}
                    className="w-full h-full"
                    src={`https://www.youtube-nocookie.com/embed/${useCase.video}`}
                    allow="encrypted-media; picture-in-picture"
                  />
                </div>
              </div>
            )}

            <div className="px-4 pb-24">
              <PipedriveForm
                tag="h2"
                title="Ihr Kontakt zu Shopify-Experten!"
              />
            </div>
          </>
        );
      }}
    </LayoutConsumer>
  );
}

export default UseCaseDetail;

export const useCaseDetailQuery = graphql`
  query UseCaseDetailQuery(
    $id: String!
  ) {
    useCasesYaml(id: { eq: $id }) {
      title
      subtitle
      description
      meta_title
      meta_description
      video
      tags
      flow {
        title
        image {
          childImageSharp {
            gatsbyImageData(
              width: 720
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
        items {
          title
          description
        }
      }
      benefits {
        icon
        title
      }
      image {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
      sample {
        title
        description
        background_color
        image {
          name
          publicURL
          childImageSharp {
            gatsbyImageData(
              width: 2000
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
    }
  }
`;
